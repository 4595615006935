import { cva } from '@mentimeter/ragnar-tailwind-config';

export const input = cva(
  [
    'appearance-none',
    'border',
    'border-solid',
    'border-strong',
    'cursor-pointer',
    'disabled:border-disabled ',
    'disabled:cursor-not-allowed',
    'disabled:bg-disabled-strong',
    'disabled:hover:bg-disabled-strong',
    'duration-300',
    'ease-in-out',
    'transition-all',
    'focus-visible:ring-offset-2',
    'focus-visible:ring-4',
    'focus-visible:ring-interactive-focused',
    'focus:ring-none',
    'relative',
    'shrink-0',
    'hover:border-secondary',
    'r-check-item', // Reset selector
  ],
  {
    variants: {
      size: {
        default: 'h-[28px] w-[28px]',
        compact: 'h-[20px] w-[20px]',
      },
      variant: {
        default: [
          'checked:bg-secondary',
          'checked:border-transparent',
          'checked:hover:bg-button-primary-checked',
        ],
        negative: [
          'checked:bg-negative',
          'checked:border-transparent',
          'checked:hover:bg-button-negative-hover',
        ],
        positive: [
          'checked:bg-positive',
          'checked:border-transparent',
          'checked:hover:bg-button-positive-hover',
        ],
      },
      type: {
        checkbox: 'rounded-lg',
        radio: 'rounded-full',
      },
    },
    compoundVariants: [
      {
        type: 'checkbox',
        size: 'compact',
        className: 'rounded',
      },
      {
        type: 'checkbox',
        variant: 'default',
        className: [
          'indeterminate:bg-secondary',
          'indeterminate:hover:bg-button-primary-checked',
          'indeterminate:border-transparent',
        ],
      },
      {
        type: 'checkbox',
        variant: 'negative',
        className: [
          'indeterminate:bg-negative',
          'indeterminate:border-transparent',
          'indeterminate:hover:bg-button-negative-hover',
        ],
      },
      {
        type: 'checkbox',
        variant: 'positive',
        className: [
          'indeterminate:border-transparent',
          'indeterminate:bg-positive',
          'indeterminate:hover:bg-button-positive-hover',
        ],
      },
    ],

    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
);

export const variants = {
  input,
};
