import React from 'react';
import type { AxiosError } from 'axios';
import axios from 'axios';
import type { ButtonT } from '@mentimeter/ragnar-ui';
import { useAppState } from 'src/appState';
import { sleep } from '../../utils/sleep';
import type { FormValuesT } from './types';

interface RenderT {
  status: ButtonT['state'] | undefined;
  handleSubscribe: (data: FormValuesT) => void;
}

const subscribeRequest: (
  eventName: string,
  data: FormValuesT,
) => Promise<{ status: string }> = async (eventName, data) => {
  try {
    const { data: responseData } = await axios.post(
      '/api/subscribe',
      { eventName, ...data },
      {
        timeout: 5000,
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
    return responseData;
  } catch (err) {
    const error = err as AxiosError;
    return error.response?.data ?? {};
  }
};

export const useSubscribe = (
  trackingEvent: string,
  extraFields?: Record<string, string>,
): RenderT => {
  const [status, setStatus] = React.useState<ButtonT['state']>();
  const { user } = useAppState();
  const hasUser = Boolean(user);

  const handleSubscribe = React.useCallback(
    async (data: FormValuesT) => {
      if (status === 'loading') {
        return;
      }

      setStatus('loading');
      const dataTopics = hasUser
        ? {
            Education: true,
            Inspiration: true,
          }
        : {
            Education: true,
            Inspiration: true,
            'Feedback surveys': false,
            'Product & feature updates': false,
            'Account notifications': false,
            MentiAcademy: false,
          };
      const ressponseSubscribe = await subscribeRequest(trackingEvent, {
        email: data.email,
        name: data.name,
        jobTitle: data.jobTitle,
        ...(data.consent && {
          cio_subscription_preferences: {
            topics: dataTopics,
          },
        }),
        ...extraFields,
      });

      if (ressponseSubscribe.status === 'OK') {
        setStatus('success');
      } else {
        setStatus('error');
        await sleep();
        setStatus(undefined);
      }
    },
    [extraFields, status, trackingEvent, hasUser],
  );

  return { status, handleSubscribe };
};
