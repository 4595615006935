import React, { type JSX } from 'react';
import { useRagnar } from '@mentimeter/ragnar-react';
import { addUnit } from '@mentimeter/ragnar-utils';
import type { DSC } from '@mentimeter/ragnar-dsc';

export const calculateIconSize = (
  theme: Pick<DSC, 'fontSizes' | 'borders'>,
  size: number,
): string =>
  `calc(${addUnit(theme.fontSizes[size] ?? 0)} - ${addUnit(
    theme.borders[1] * 2,
  )})`;

const iconSizes = {
  default: 5,
  compact: 3,
};

function RadioButtonIcon({ size }: { size: string }): JSX.Element {
  const { theme } = useRagnar();

  return (
    <Wrapper id="radiobutton" size={size}>
      <circle fill={theme.colors.onSecondary} cx="12" cy="12" r="5" />
    </Wrapper>
  );
}

export function CheckboxIcon({ size }: { size: string }): JSX.Element {
  const { theme } = useRagnar();

  return (
    <Wrapper id="checkbox" size={size}>
      <path
        fill={theme.colors.onSecondary}
        d="M20.268 7.031 9.562 17.737l-5.83-5.83L5.5 10.138 9.563 14.2 18.5 5.263l1.768 1.768Z"
      />
    </Wrapper>
  );
}

export const IndeterminateIcon = ({ size }: { size: string }): JSX.Element => {
  const { theme } = useRagnar();
  return (
    <Wrapper id="indeterminate" size={size}>
      <path fill={theme.colors.onSecondary} d="M4 10h16v4H4z" />
    </Wrapper>
  );
};

const Wrapper = ({
  size,
  id,
  children,
}: {
  size: string;
  id: string;
  children: any;
}): JSX.Element => {
  return (
    <svg
      className="block absolute pointer-events-none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      data-testid={`${id}-icon`}
    >
      {children}
    </svg>
  );
};

export const Icon = ({
  type,
  indeterminate,
  variant,
  checked,
}: {
  type: 'checkbox' | 'radio';
  indeterminate: boolean;
  variant: 'compact' | 'default';
  checked: boolean | undefined;
}) => {
  const { theme } = useRagnar();
  const iconSize = calculateIconSize(theme, iconSizes[variant]);

  switch (type) {
    case 'checkbox':
      if (indeterminate) return <IndeterminateIcon size={iconSize} />;
      if (checked) return <CheckboxIcon size={iconSize} />;
      else return;
    case 'radio':
      if (checked) return <RadioButtonIcon size={iconSize} />;
      return;
    default:
      return;
  }
};
