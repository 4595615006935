import React, { useRef } from 'react';
import { filterValidProps, styled } from '@mentimeter/ragnar-styled';
import { cn } from '@mentimeter/ragnar-tailwind-config';
import { Icon } from './Icon';
import type { CheckPropsT } from './CheckItem.types';
import { variants } from './variants';

const _CheckItem = ({
  checked,
  className,
  variant = 'default',
  id,
  type = 'checkbox',
  size = 'default',
  indeterminate = false,
  disabled = false,
  readOnly = false,
  ...rest
}: CheckPropsT) => {
  const checkRef = useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (!checkRef.current) {
      return;
    }
    checkRef.current.indeterminate = indeterminate;
  }, [indeterminate]);

  return (
    <span
      className={cn(
        'flex gap-2 items-center justify-center check-item',
        className,
      )}
    >
      <input
        disabled={disabled}
        className={cn(variants.input({ variant, size, type }))}
        ref={checkRef}
        id={id}
        type={type}
        checked={checked && !indeterminate}
        readOnly={readOnly}
        {...filterValidProps(rest)}
      />

      <Icon
        type={type}
        indeterminate={indeterminate}
        checked={checked}
        variant={size}
      />
    </span>
  );
};

const FelaCheckItem = styled(_CheckItem, { displayName: 'Check' })(
  'kosmosSpacing',
);

export const CheckItem = React.forwardRef(
  (props: React.ComponentProps<typeof FelaCheckItem>, ref) => {
    return <FelaCheckItem {...props} ref={ref} />;
  },
);
